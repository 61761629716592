import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import RelatedArticles from "../components/relatedCodeSnippets"
import ToolCard from "../components/cards/ToolCard";
import InArticleAd from "../components/adUnits/inArticleAd"

import ExtensionSearchThumbnail from "../img/infoGraphics/search.png"

// eslint-disable-next-line
export const CodeSnippetsTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  index
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section key={index} className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="content-title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            {/* <p>{description}</p> */}
            <PostContent content={content} />
          </div>
        </div>
      </div>
      {tags && tags.length ? (
        <div style={{ marginTop: `4rem` }}>
          <h4>Tags</h4>
          <ul className="tags">
            {tags.map((tag) => (
              <Link to={`/tags/${kebabCase(tag)}/`}>
                <span className="tag" key={tag + `tag`}>
                  {tag}
                </span>
              </Link>
            ))}
          </ul>
        </div>
      ) : null}
      <div style={{ marginTop: `3rem` }}>
        <ToolCard 
          type="extension"
          title="NitroGAS Chrome Extension"
          description="Want to reference this code snippet right from your Google Apps Script Project? Check out our NitroGAS Chrome Extension. This tool will help you build your scripts faster than you could imagine. The tool itself and access to the code snippets are 100% FREE - Happy Coding!"
          thumbnail={ExtensionSearchThumbnail}
          pageUrl="https://chrome.google.com/webstore/detail/bootstrapping-tools-nitro/najbgpipgacnhjcaabbhdaddlmebmopb"
          cta='Get the Extension'
        />
      </div>
    </section>
  );
};

CodeSnippetsTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const CodePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CodeSnippetsTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      <InArticleAd />
      <RelatedArticles tags={post.frontmatter.tags && post.frontmatter.tags.toString().split(",")} limit={3} title={post.frontmatter.title} />
    </Layout>
  );
};

CodePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CodePost;

export const pageQuery = graphql`
  query CodeSnippetByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
