import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby"
import InFeedAd from "../../components/adUnits/inFeedAd"

export default function ToolsCard(props) {
    const {
      thumbnail,
      title,
      description,
      pageUrl,
      cta,
      index,
      type
    } = props;
  
    return (
      <React.Fragment>
        <div key={index} className="has-text-left">
            <div className="columns is-multiline">
                <div className="card column is-12">
                    <div className="card-content">
                        <div className="media">
                            <div className="media-left">
                              {type === 'extension' ? 
                              <Link to={pageUrl} target="_blank">
                                <figure className="image is-16x9">
                                    <img 
                                        id="toolThumbnail"
                                        src={thumbnail}
                                        alt="thumbnail"
                                    />
                                </figure>
                              </Link>
                              :
                              <Link to={pageUrl}>
                                <figure className="image is-16x9">
                                    <img 
                                        id="toolThumbnail"
                                        src={thumbnail}
                                        alt="thumbnail"
                                    />
                                </figure>
                              </Link>
                            }
                            </div>
                            <div className="media-content">
                            {type === 'extension' ? 
                              <Link to={pageUrl} target="_blank">
                                <h2 className="title">
                                  <p className="is-4" style={{color: "#156AE3"}}>
                                    {title}
                                  </p>
                                </h2>
                              </Link>
                            :
                              <Link to={pageUrl}>
                                <h2 className="title">
                                  <p className="is-4" style={{color: "#156AE3"}}>
                                    {title}
                                  </p>
                                </h2>
                              </Link>
                            }
                              <p className="is-6" style={{paddingTop: "12px"}}>
                                  {description}
                              </p>
                              {type === 'extension' ? 
                              <Link className="button is-card-cta is-size-6-tablet is-size-7-touch" to={pageUrl} target="_blank">
                                {cta}
                              </Link>
                              :
                              <Link className="button is-card-cta is-size-6-tablet is-size-7-touch" to={pageUrl}>
                                {cta}
                              </Link>
                              }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { index == 0 ? 
          <InFeedAd />
        :
          <></>
        }
      </React.Fragment>
    );
  }
  
  ToolsCard.propTypes = {
    thumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    pageUrl: PropTypes.string
  };