import React from "react";
import { Link, StaticQuery, graphql } from "gatsby"

const RelatedCodeSnippets = ({ posts }) => {
  return (
    <div className="related-section">
        <div className="columns is-multiline">
        {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div className="is-parent column is-12" key={node.id}>
                <article
                  className={`code-list-item tile is-child box ${
                    node.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header>
                    <Link
                      className="card-title is-size-6"
                      to={node.fields.slug}
                    >
                      <p className="post-meta">
                        {node.frontmatter.title}
                      </p>
                    </Link>
                  </header>
                  <Link
                    className="card-title is-size-7"
                    to={node.fields.slug}
                  >
                    <deckgo-highlight-code language="javascript" terminal="carbon" theme="dracula">
                      <code slot="code">
                        {node.frontmatter.description.replaceAll(/(```javascript|```)/gi,'')}
                      </code>
                    </deckgo-highlight-code>
                  </Link>
                </article>
              </div>
            );
        })}
        </div>
    </div>
  );
};

export const relatedCodeSnippetsQuery = graphql`
  query {
    allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "code-snippets" } } }
        ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            featuredimage {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    quality: 100
                    layout: CONSTRAINED
                  )

                }
            }
          }
        }
      }
    }
  }
`;

export default (props) => {
  const related = [];

  return (
    <StaticQuery
      query={relatedCodeSnippetsQuery}
      render={(data) => {
        const {
          allMarkdownRemark: { edges },
        } = data;

        // iterate through article vertex
        for (const vertex of edges) {
          // handle base case
          if (related.length >= props.limit) {
            continue;
          }

          // abstract tags
          const {
            frontmatter: { tags },
          } = vertex.node;

          // handle case where there are no tags or prop tags
          if (!tags || !props.tags) {
            continue;
          }

          const formattedTags = tags.toString().split(",");

          // ensure tags match and article is not the same as current
          if (
            props.tags.some((item) => formattedTags.includes(item)) &&
            props.title !== vertex.node.frontmatter.title
          ) {
            related.push(vertex);
          }
        }

        // render posts
        return (
          <section className="section">
            {related.length > 0 && 
              <div>
                <hr />
                <h3 className="related-title">
                  Other Helpful Code Snippets:
                </h3>
                <RelatedCodeSnippets posts={related} />
              </div>
            }
          </section>
        );
      }}
    />
  );
};